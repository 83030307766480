import React from 'react';
import ReactDOM from 'react-dom';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { makeStyles } from '@mui/styles';
import App from './app';
import { AuthProvider } from './AuthContext';

const theme = createTheme({
  palette: {
    primary: { main: "#2196f3" },
    secondary: { main: "#f84c89" }
  },
  shape: { borderRadius: 8 }
});

const useStyles = makeStyles({

});


ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('app')
);