import React from 'react';
import { useEffect } from 'react';  // Correct the import of useEffect
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { makeStyles } from '@mui/styles';
import Login from './login';
import Dashboard from './dashboard';
import { useAuth, AuthProvider } from './AuthContext';
import { useNavigate } from 'react-router-dom'; // Ensure proper import

const theme = createTheme({
  palette: {
    primary: { main: "#2196f3" },
    secondary: { main: "#f50057" }
  },
  shape: { borderRadius: 8 }
});

const useStyles = makeStyles({
  root: {
    // example style
  }
});

const AppWrapper = () => {
  const { isAuthenticated } = useAuth();  // Correct use of useAuth
  const navigate = useNavigate();

  useEffect(() => {  // Correct use of useEffect
    // Redirect based on authentication status
    if (isAuthenticated) {
      navigate("/dashboard", { replace: true });

    } else {
      navigate("/login", { replace: true });

    }
  }, [isAuthenticated, navigate]);

  return <Outlet />; // This component renders child routes, needed for React Router v6
};

const App = () => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<AppWrapper />}>
              <Route index element={<Navigate to="login" replace />} />
              <Route path="login" element={<Login />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="*" element={<Navigate to="login" replace />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
};

export default App;
