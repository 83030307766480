import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';

const API_BASE_URL = window.location.protocol + '//' + window.location.hostname;

function SignIn() {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');

  const searchParams = new URLSearchParams(location.search);
  const cliente = searchParams.get('cliente');
  
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      setErrorMessage('');

      try {
          const response = await axios.post(`${API_BASE_URL}/login`, {
              username: data.get('username'),
              password: data.get('password'),
          }, {
              withCredentials: true
          });

          const userData = response.data.userData;
          if (userData) {
              navigate('/dashboard', { replace: true, state: { cnpj: userData.cnpj, empresa: userData.empresa, username: userData.username } });
			  navigate(0);
		  } else {
              console.error('No user data returned from the server');
              setErrorMessage('No user data returned from the server');
          }
      } catch (error) {
          console.error('Error executing query:', error);
          if (axios.isAxiosError(error) && error.response && error.response.data.message) {
              setErrorMessage(error.response.data.message);
          } else {
              setErrorMessage('Um erro inesperado ocorreu.');
          }
      }
  };

  return (
    <ThemeProvider theme={createTheme()}>
        <CssBaseline />
        <Grid container component="main" sx={{ height: '100vh' }}>
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
              backgroundImage: 'url(/login.jpg)',
              backgroundRepeat: 'no-repeat',
              backgroundColor: (t) =>
                t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          />
          <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square sx={{ filter: 'invert(1)' }}>
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Entrar
              </Typography>
              {errorMessage && <Alert severity="error" sx={{ width: '100%', mt: 2 }}>{errorMessage}</Alert>}
              <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  label="Login"
                  name="username"
                  autoComplete="username"
                  autoFocus
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Senha"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Entrar
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
    </ThemeProvider>
  );
}

export default SignIn;
