import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';

interface AuthContextType {
  isAuthenticated: boolean;
}

// Providing a structured default value with explicit type
const AuthContext = createContext<AuthContextType>({ isAuthenticated: false });

const getStorage = async (name: string): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    // console.log('Checking cookies:', document.cookie);  // Log all cookies to see what's available
    const nameEQ = encodeURIComponent(name) + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i].trim();
      if (c.indexOf(nameEQ) === 0) {
        // console.log('Cookie found:', c);  // Confirm which cookie is found
        resolve(true);
        return;
      }
    }
    // console.log('Cookie not found:', name);  // Indicate that the cookie was not found
    resolve(false);
  });
}

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchAuth = async () => {
      const authenticated = await getStorage('authToken');
      setIsAuthenticated(authenticated);
      setIsLoading(false); // Set loading to false after checking
    };
    fetchAuth();

    const checkAuth = async () => {
      const authenticated = await getStorage('authToken');
      setIsAuthenticated(authenticated);
    };

    // Check auth state periodically if needed
    const interval = setInterval(() => {
      checkAuth();
    }, 100000);  // Check every 10 seconds

    window.addEventListener('storage', () => {
      checkAuth();
    });  // Also update on storage changes

    return () => {
      window.removeEventListener('storage', () => {
        checkAuth();
      });
      clearInterval(interval);
    };
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

